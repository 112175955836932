import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import ParagraphHeroV3 from "../components/paragraphs/hero-v3";
import ParagraphCardsContainer from "../components/paragraphs/cards-container";
import ParagraphZebra from "../components/paragraphs/zebra";
import {ParagraphZebraCardSmall} from "../components/paragraphs/zebra-card";
import ParagraphTemplateTabsContainer from "../components/paragraphs/template-tabs-container";
import BlockContentLogos from "../components/blocks/content-logos";
import BlockContentTestimonial from "../components/blocks/content-testimonial";
import BlockContentLogosSlider from "../components/blocks/content-logos-slider";
import ParagraphFaq from "../components/paragraphs/faq";
import SeparatorIcon from "../images/chevron-right-large-gray.svg";
import ArticlesSection from "../components/articles";

const IndexPage = ({ data: { node } }) => {
  //  console.log(node);

  const { relationships } = node;

  const {
    cards1,
    cards2,
    field_carousel_logos,
    field_integration_logos,
    hero,
    zebra,
    single,
    templates,
    testimonials,
    faq,
    articles,
  } = relationships;
  //let hero = relationships.hero;
  //hero.cta2 = cta2;

  //console.log("articles", articles);

  const seo = getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  );

  return (
    <Layout showCta={false} /*isExperimentalPage={true}*/>
      <Seo {...seo} />

      <section className="pt-[90px] md:pt-[72px]">
        <div className="h-[60px] container --max-w-screen-xl mx-auto px-4 lg:px-6 flex flex-row items-center gap-2.5 text-sm text-[#3D434C] leading-[20px]">
          <div className=" underline">Use Cases</div>
          <div>
            <img src={SeparatorIcon} alt="" />
          </div>
          <div className="font-medium">Revenue Reporting</div>
        </div>
      </section>

      <section className="bg-top bg-no-repeat  bg-contain	">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-0 pt-[90px] md:pt-[66px] pb-[90px] ">
          <ParagraphHeroV3
            data={hero}
            //context="frontpage"
            // className="pb-[44px]"
          >
            <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
              <ParagraphCardsContainer
                data={cards2}
                context="hero-small"
                classNameCards="flex flex-col lg:flex-row justify-around items-center gap-6 md:gap-1 "
                className=""
              />
            </div>
          </ParagraphHeroV3>

          <BlockContentLogosSlider
            data={field_carousel_logos}
            className="my-10 md:mt-[90px] md:mb-[10px] space-y-5"
            //context="slider"
          />
        </div>
      </section>

      <section className="bg-[#F9F8F7] py-8 lg:py-[100px]">
        <div className="max-w-screen-xl mx-auto px-4 xl:px-0 --space-y-4 --lg:space-y-[100px]">
          <ParagraphZebra
            data={zebra}
            className=" --space-y-20"
            classNameCards="--space-y-4 lg:space-y-[100px] "
            context="not zebra"
          />
        </div>
      </section>

      <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
        <ParagraphTemplateTabsContainer
          data={templates}
          className="py-12 md:py-[100px]  space-y-10"
          context="simple"
        />
      </div>

      <section className="pb-[70px]">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <BlockContentTestimonial data={testimonials} />
        </div>
      </section>

      <section className="py-8 md:py-[70px] bg-[#F9F8F7]">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <BlockContentLogos
            data={field_integration_logos}
            context="section"
            className="space-y-8 md:space-y-[50px]"
          />
        </div>
      </section>

      <section className="">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <ParagraphCardsContainer
            data={cards1}
            context="frontpage-bottom"
            classNameCards="flex flex-col lg:flex-row justify-between items-top gap-6 md:gap-5 "
            className="space-y-10 md:space-y-[70px] py-16 md:pt-[140px] md:pb-[60px]"
          />
        </div>
      </section>

      <section className="py-[70px]">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <ParagraphFaq data={faq} />
        </div>
      </section>

      <section className="bg-[#F9F8F7] py-[100px]">
        <div className="container px-0 space-y-[100px]">
          <h2 className="text-center text-[60px] leading-[66px] text-[#3D434C] tracking-[-0.04em]">
            Sightfull Blog
          </h2>
          <ArticlesSection
            articles={articles}
            className="bg-[#F9F8F7] "
            classNameCards="container lg:px-0 mx-auto --py-12 grid grid-cols-1 md:grid-cols-3 justify-between gap-[29px] max-w-[1108px] "
            classNameCard="border border-[#D7DFE9] rounded-[10px] px-2.5 pt-2.5 pb-[30px] bg-white space-y-5 "
          />
        </div>
      </section>

      {/* 
      <section className="bg-[#1A1D21] text-white py-6 md:py-10">
        <div className="max-w-screen-xl px-4 md:px-0 mx-auto  flex flex-row items-center justify-between text-[20px] md:text-[32px] font-normal leading-[24px] md:leading-[35px] tracking-[-0.03em]">
          <h2 className="">{"Get started with Sightfull"}</h2>

          <Link to={"/book-a-demo/"} className="block px-4">
            {"Book a Demo"} <span className="tracking-normal">{"-->"}</span>
          </Link>
        </div>
      </section> */}

      <section>
        <div className="container pt-[120px] pb-[89px] space-y-[30px]">
          <ParagraphZebraCardSmall data={single} context="cta" />
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query revenueReporting($id: String!) {
    node: nodeRevenueReporting(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      relationships {
        hero: field_hero {
          ...ParagraphGenericBlock
        }
        faq: field_faq {
          ...ParagraphFaq
        }
        testimonials: field_testimonials {
          ...BlockContentTestimonial
        }
        zebra: field_cards_section {
          ...ParagraphZebra
        }
        field_carousel_logos {
          ...BlockContentLogos
        }
        cards2: field_cards2 {
          ...ParagraphCardsContainer
        }
        cards1: field_cards1 {
          ...ParagraphCardsContainer
        }
        templates: field_templates_tabs {
          ...ParagraphTemplateTabsContainer
        }
        field_integration_logos {
          ...BlockContentLogos
        }
        articles: field_articles {
          ... on node__blog {
            ...NodeBlogTeaser
          }
        }
        single: field_paragraph_single {
          __typename
          title: field_title
          links: field_links {
            title
            uri
          }
        }
      }
    }
  }
`;
